import { forwardRef } from "react"
import styled, { css } from "styled-components"



const ExtraBorder = forwardRef((props, ref) => {
    
    const { position, height, width = 92 } = props

    return (
        <Wrapper className={'backdrop-blur-sm rounded-xl absolute'} width={width} height={height || ref?.current?.clientHeight} position={position}>

        </Wrapper>
    )
})

const Wrapper = styled.div`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-image: linear-gradient(45deg, #FFFFFF 0%, #0673F100 100%);
    z-index: 0;
    ${props => props.position === 'top-left' ?
        css`
            left: -1px;
            top: -3px;
            background-image: linear-gradient(45deg, #FFFFFF 0%, #0673F100 100%);
        `
    : null};
    ${props => props.position === 'top-right' ?
        css`
            right: -1px;
            top: -1px;
            background-image: linear-gradient(45deg, #0673F100 0%, #FFFFFF 100%);
        `
    : null};
    ${props => props.position === 'bottom-left' ?
        css`
            left: -1px;
            bottom: -1px;
            background-image: linear-gradient(45deg, #FFFFFF 0%, #0673F100 100%);
        `
    : null};
`

export default ExtraBorder