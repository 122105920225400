import styled from 'styled-components'
import {
	BalancesIcon,
	BottomBarBalancesIcon,
	BottomBarSettingsIcon,
	ChartIcon,
	DashboardIcon,
	TradeIcon,
} from '../common/icons'
import { Link, useLocation } from 'react-router-dom'
import Text from '../../core/utils/Text'
import MobileModal from '../modals/MobileModal'
import { useEffect, useState } from 'react'
import SettingModal from '../modals/SettingModal'
import TradeModal from '../modals/TradeModal'
import { useMainContext } from '../../core/contexts/main'
import MenuModal from '../modals/MenuModal'
import { ReactComponent as AiNav } from "../../assets/dexco/nav/ai.svg"
import { ReactComponent as WalletNav } from "../../assets/dexco/nav/wallet.svg"
import { ReactComponent as ProfileNav } from "../../assets/dexco/nav/profile.svg"
import { ReactComponent as DashboardNav } from "../../assets/dexco/nav/dashboard.svg"

const LINKS = [
	{ text: 'menu', Icon: DashboardIcon, href: '#' },
	{ text: 'transactions', Icon: ChartIcon, href: '/transaction-history' },
	{ text: 'trade', Icon: TradeIcon, href: '#' },
	{ text: 'your-balance', Icon: BottomBarBalancesIcon, href: '/wallets' },
	{ text: 'settings', Icon: BottomBarSettingsIcon, href: '#' },
]

export default function NewBottomBar() {
	const {
		main: { theme },
	} = useMainContext()

	const [activeTab, setActiveTab] = useState(0)

	const initialModal = { type: null, open: false }
	const [modal, setModal] = useState(initialModal)

	const openModal = (type) => setModal({ type, open: true })
	const closeModal = () => setModal(initialModal)

	const { pathname } = useLocation()
	// console.log({ pathname })

	useEffect(() => {

		if (pathname === '/dashboard') setActiveTab(0)
		else if (pathname === '/otc' || pathname.includes('trade')) setActiveTab(2)
		else if (pathname.startsWith('/wallets') || pathname === '/log') setActiveTab(3)
		else if (pathname === '/transaction-history') setActiveTab(1)
	}, [pathname])

	return (
		<>
		<div 
			className={'fixed bottom-[42px] rounded-[50%] w-[56px] cursor-pointer z-[103] h-[56px] bg-[#0673F1] left-[50%] translate-x-[-50%] rotate-180'}
			style={{ boxShadow: '0px -3px 23.7px 0px #0673F1AB' }}
		>
			<div className={'w-full h-full flex items-center justify-center'}>

			</div>
		</div>
		<div
			className="w-[95%] fixed z-[102] bottom-[10px] left-[2.5%] h-[60px] rotate-180 bg-[#1F273C] rounded-md flex items-center justify-between"
			style={{
				maskImage: "radial-gradient(circle at 50% 0%, transparent 21%, black 21.5%)",
				WebkitMaskImage: "radial-gradient(circle at 50% 105%, transparent 21%, black 21.5%)",
			}}
	  	>
		<div className={'rotate-180 flex items-center w-[40%] justify-center gap-4'}>

			<div className={'cursor-pointer flex flex-col items-center justify-center gap-1'}>
				<WalletNav width={22} />
				<span className={'dark:text-slate-300 text-[0.7rem]'}>تراکنش ها</span>
			</div>
			<div className={'cursor-pointer flex flex-col items-center justify-center gap-1'}>
				<DashboardNav width={22} />
				<span className={'dark:text-slate-300 text-[0.7rem]'}>اکسفینیتو</span>
			</div>

		</div>

		<div className={'fixed top-0 left-[50%] translate-x-[-50%] rotate-180'}>
			<span className={'text-xs dark:text-slate-300'}>ترید</span>
		</div>

		<div className={'rotate-180 flex items-center w-[40%] justify-center gap-4'}>

			<div className={'cursor-pointer flex flex-col items-center justify-center gap-1'}>
				<ProfileNav width={22} />
				<span className={'dark:text-slate-300 text-[0.7rem]'}>پروفایل</span>
			</div>
			<div className={'cursor-pointer flex flex-col items-center justify-center gap-1'}>
				<AiNav width={22} />
				<span className={'dark:text-slate-300 text-[0.7rem]'}>هوش مصنوعی</span>
			</div>

		</div>
	  </div>
		</>

	)
}
